/* const response = await fetch('http://51.12.243.110:8000/api/isAuth/', {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    },
    
    });
    
    const data = await response.json();


const token = localStorage.getItem("mytoken")
console.log("Çalıştı",data.first_name)
const globalVariable = data.first_name
const isSign = data.isSign;
const list = [globalVariable,isSign]
export default list; */
import { useEffect, useState } from 'react';


export default function useList() {
  const [list, setList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        const token = localStorage.getItem("mytoken");
        
        
        // localStorage'dan token'i al
        

        // Verileri listeye ekle
        
        if (token){
            const response = await fetch('/api/post/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body : JSON.stringify({token})
            });


            const data = await response.json();
            if(data.iserror){
              if (data.another){
                alert(data.message)
              }
              
              localStorage.removeItem("mytoken");
              window.location.href = "https://llmwizard.com/AuthPage"
            }else{
              const globalVariable = data.user.first_name;
              const email = data.user.email; 
              const id = data.user.id; 
              const role = data.user.role;

              const newList = [globalVariable, true , email , id,role]; 
              setList(newList);
            }
            
        }else{

            const newList = [null, false];
            setList(newList);
        }
        
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // useEffect sadece bir kere çalışsın diye boş bağımlılık dizisi geçiyoruz

  return list;
}
