import Head from "next/head";
import { useEffect } from "react";

const PageHead = ({ title }) => {
  useEffect(() => {
    const handleGtag = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-D9JM11KN2M');
    };

    // Load the gtag script asynchronously
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-D9JM11KN2M";
    script.async = true;
    script.onload = handleGtag; // Ensure gtag is called after the script is loaded

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script); // Cleanup the script when component unmounts
    };
  }, []);

  return (
    <>
      <Head>
        <title>{title} || llmwizard </title>
        <meta name="description" content="Page Description" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/llmwizard1.ico" />
      </Head>
    </>
  );
};

export default PageHead;
