import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useList from '@/components/isAuth/isAuth';

const DesignBlogsHome = () => {
  const list = useList();
  const userID = list[0];
  const { formatMessage } = useIntl();

  return (
    <>
      <div className='concontainer'>
        <div className="grid-container">
          <div className="feature">
            <h3 className="feature-title">
              <i className="feather-edit-3 icon"></i>
              <FormattedMessage id="blogs.feature1.title" defaultMessage="Access multiple AI models" />
            </h3>
            <p>
              <FormattedMessage 
                id="blogs.feature1.description" 
                defaultMessage="Utilize ChatGPT, Claude, and Gemini Pro in one interface. Choose the best AI for each task or combine their strengths for optimal results."
              />
            </p>
          </div>
          <div className="feature">
            <h3 className="feature-title">
              <i className="feather-book-open icon"></i>
              <FormattedMessage id="blogs.feature2.title" defaultMessage="    Powerful Assistant Capabilities" />
            </h3>
            <p>
              <FormattedMessage 
                id="blogs.feature2.description" 
                defaultMessage="   Leverage advanced AI assistants to handle complex tasks. Create specialized agents that can understand context, follow instructions, and provide intelligent, adaptive responses to your users' needs."
              />
            </p>
          </div>
          <div className="feature">
            <h3 className="feature-title">
              <i className="feather-share-2 icon"></i>
              <FormattedMessage id="blogs.feature3.title" defaultMessage=" Seamless integration" />
            </h3>
            <p>
              <FormattedMessage 
                id="blogs.feature3.description" 
                defaultMessage="  Easily integrate your multi-AI chatbot into your existing systems. Create powerful workflows and automate customer support across multiple platforms.
"
              />
            </p>
          </div>
        </div>

       
      </div>
      <style jsx>{`
        .concontainer {
          width: 100vw;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 2rem;
        }
        .grid-container {
          display: grid;
          grid-template-columns: 1fr;
          gap: 2.5rem;
          width: 76%;
          margin-top: 3rem;
        }
        .feature {
          text-align: left;
        }
        .feature-title {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
        }
        .icon {
          margin-right: 0.5rem;
          color: #FFBD4C;
          font-size: 24px;
        }
        .cta-text {
          font-size: 2.25rem;
          font-weight: bold;
          margin-top: 4rem;
          margin-bottom: 2rem;
          line-height: 1.25;
          text-align: center;
        }
        @media (min-width: 768px) {
          .grid-container {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      `}</style>
    </>
  );
};

export default DesignBlogsHome;
