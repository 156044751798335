import Header from "@/components/Header/Header";
import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Context from "@/context/Context";
import Footer from "@/components/Footer/Footer";
import Copyright from "@/components/Footer/Copyright";
import UserAuth from "@/components/Auth/UserAuth";
import Head from "next/head"; // Head bileşenini ekliyoruz


const AuthPage = ( {handleLanguageChange}) => {
  return (
    <>
    <Head>
        {/* Google Analytics Kodu */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4EMBSQJE87"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4EMBSQJE87');
          `,
          }}
        />
      </Head>

      <main className="page-wrapper">
        <Context>
          {/*<HeaderTop />*/}
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
            handleLanguageChange={handleLanguageChange}
          />
          <PopupMobileMenu />

          <UserAuth />

          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default AuthPage;
