import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useList from '@/components/isAuth/isAuth';

const DesignHomeHead = () => {
  const [animate, setAnimate] = useState(false);
  const { formatMessage } = useIntl();
  
  const list = useList();
  const userID = list[0];

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <>
      <div className='concontainer'>
        <div className="header-container">
          <div className={`main-text ${animate ? 'animate' : ''}`}>
            <FormattedMessage id="Home.design.title" />
          </div>
          <div className={`home-head ${animate ? 'animate' : ''}`}>
           <FormattedMessage id='Home.design.subtitle' />
          </div>
          <button className={`try-free-button ${animate ? 'animate' : ''}`} onClick={() => {
                  userID ? window.location.href = `/chat` :  window.location.href = `/AuthPage/`;
          }} >
            <FormattedMessage id="Home.tryFree" defaultMessage="Try free" />
          </button>
        </div>
      </div>
      <style jsx>{`
  .concontainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 900px;
    margin-top: 100px;
  }
  .main-text {
    font-size: 72px;
    font-weight: bold;
    color: #123E7B;
    background: linear-gradient(to right, #3B7BEB 30%, #FFBD4C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
    opacity: 0;
    transform: translateZ(-100px);
    transition: opacity 0.5s, transform 0.5s;
  }
  .home-head {
    font-size: 20px;
    font-weight: bold;
    color: #123E7B;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
    word-wrap: break-word;
    max-width: 100%;
    opacity: 0;
    transform: translateZ(-100px);
    transition: opacity 0.5s, transform 0.5s;
    transition-delay: 0.2s;
  }
  .try-free-button {
    margin-top: 30px;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    background-color: #3B7BEB;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.5s, transform 0.5s;
    opacity: 0;
    transform: translateZ(-100px);
    transition-delay: 0.4s;
  }
  .try-free-button:hover {
    background-color: #2864C7;
  }
  .main-text.animate,
  .home-head.animate,
  .try-free-button.animate {
    opacity: 1;
    transform: translateZ(0);
  }

  /* Mobile responsive styles */
  @media (max-width: 768px) {
    .header-container {
      margin-top: 100px;
    }
    .main-text {
      font-size: 48px;
    }
    .home-head {
      font-size: 18px;
    }
    .try-free-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }

  /* Small mobile devices */
  @media (max-width: 480px) {
    .main-text {
      font-size: 36px;
    }
    .home-head {
      font-size: 16px;
    }
    .try-free-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
`}</style>
    </>
  );
};

export default DesignHomeHead;
