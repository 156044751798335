import React, {useRef, useEffect, useState } from 'react';
import myImage from '../../public/images/logo/ProdoxIcon.png';
import Item from '../items/Item.js';
import Image from "next/image";
import useList from '@/components/isAuth/isAuth';
import { useAppContext } from "@/context/Context";
import { FormattedMessage,useIntl } from 'react-intl';
import myImage1 from '../../public/images/bot-icons/IMG-20240612-WA0003.jpg';
import myImage2 from '../../public/images/bot-icons/IMG-20240612-WA0004.jpg';
import myImage3 from '../../public/images/bot-icons/IMG-20240612-WA0005.jpg';
import myImage4 from '../../public/images/bot-icons/IMG-20240612-WA0006.jpg';
import myImage5 from '../../public/images/bot-icons/IMG-20240612-WA0007.jpg';
import myImage6 from '../../public/images/bot-icons/IMG-20240612-WA0008.jpg';
import myImage7 from '../../public/images/bot-icons/IMG-20240612-WA0009.jpg';
import myImage8 from '../../public/images/bot-icons/IMG-20240612-WA0010.jpg';
import myImage9 from '../../public/images/bot-icons/IMG-20240612-WA0011.jpg';
import myImage10 from '../../public/images/bot-icons/IMG-20240612-WA0012.jpg';
import myImage11 from '../../public/images/bot-icons/IMG-20240612-WA0013.jpg';
import myImage12 from '../../public/images/bot-icons/IMG-20240612-WA0014.jpg';
import { useRouter } from 'next/router';

import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact tema CSS
import 'primereact/resources/primereact.min.css';         // PrimeReact temel CSS
import 'primeicons/primeicons.css';  
        


import { useDispatch, useSelector } from "react-redux";
import { setDataSettings } from "@/slices/feedbackSlice";
import { TailSpin } from 'react-loader-spinner'; // Importing a spinner component

function ImageGenerator() {

  const toast = useRef(null);
  const intl = useIntl();
  const context = useAppContext() || {}; // useAppContext undefined dönerse boş obje kullanılır.
  const { shouldCollapseLeftbar = false } = context;
  const list = useList();
  const [data, setData] = useState(null);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [customid, setCustomid] = useState();
  const [getTemplatesLoading, setGetTemplatesLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)

  const images = [myImage1, myImage2, myImage3, myImage4, myImage5, myImage6, myImage7, myImage8, myImage9, myImage10, myImage11, myImage12];

  const Container = ({ data }) => {
    return (
      <Item item={data} />
    );
  };

  const fetchData = async () => {
    try {
      const response = await fetch('/api/my_chat_bots/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: list[3] }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("res",result)
        if (result.templates && result.templates.length > 0) {
          setData(result);
        }
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (list[3]) {
      fetchData();
    }
  }, [list[3]]);

  useEffect(() => {
    const get_templates = async () => {
      let language = localStorage.getItem('language');
      if (!language) {
        // Eğer language yoksa varsayılan olarak 'en' ekle
        language = 'en';
        localStorage.setItem('language', language);
      }
      
      if (language == 'en'){
        setCustomid('7')
      }else{
        setCustomid('25')
      }
      setGetTemplatesLoading(true)
      try {
        const response = await fetch(`/api/getTemplates/`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({"language": language })
        });
        const data = await response.json();
        console.log(data.employees , "datasss")
        setTemplates(data.employees);
        if (data) {
          setGetTemplatesLoading(false)
        }
      } catch (e) {
        console.error(e);
        setGetTemplatesLoading(false)
      }
    }
    get_templates();
  }, []);

  

  const try_it = async (event) => {
    
    const url = event.target.value
    console.log(url)
    try {
      const template_id = event.target.id;
      console.log(template_id)
      const response = await fetch(`/api/setup/${url}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "template_id": template_id, "custom_name": list[0], "user_id": list[3] })
      });
      const data = await response.json();
      const user_template_id=data.user_template_id;

      if (user_template_id){
        window.location.href = `/setup/${url}=${user_template_id}`
      }
      else{
        toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.warning" }), detail:data.error});
      }
     

    } catch (e) {
      console.error(e);
    }
  }

  const createBlankChatbot = async () => {
    setCreateLoading(true)

    try {
      const response = await fetch(`/api/template_setting/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "template_id": 19, "custom_name": list[ 0], "user_id": list[3] })
      });
  
      const data = await response.json();
      window.location.href = `/setup/`;
      dispatch(setDataSettings("data.get_setting"));
      if(data.length > 1){
        setCreateLoading(false)
      }

    } catch (error) {
      setCreateLoading(false)
      toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:intl.formatMessage({ id: "toast.error.server" })});
       
    }
  }

  return (
    <div className={`rbt-main-content mr--0 mb--0 ${shouldCollapseLeftbar ? "expandedMain" : ""}`}>
    <Toast ref={toast} />
      <div className='rbt-daynamic-page-content'>
        <div className="customApp">
          <div className='customBody'>
            <div className='customBodyHeadContainer'>
              

              <button id={customid} value={'custom_ai_assistant'} onClick={try_it} className='customBtn'>
                {
             
                        <FormattedMessage id="templates.create.custom.assistant" />
               

                    
                }

              </button>

              <div className='customLine'></div>
            </div>
            <div className='customBodyContent'>
              {getTemplatesLoading ? (
                <div className='customBodyContentSpinnerContainer' >
                  <TailSpin color="#00BFFF" height={40} width={40} />
                </div>

              ) : (
                <p className='customContentTitle'><FormattedMessage id="templates.create.explore" /></p>
              )}

            <div className='customContentContainer'>
              {templates && templates
                .filter(item => item.template_id !== 7 && item.template_id !== 25)
                .map((item, index) => {
                  const isUsed = data?.templates.some(template => template.template_id === item.template_id);

                  const handleClick = (event) => {
                    if (isUsed) {
                      toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.try_it" })});
                      event.preventDefault(); // İşlemi durdur
                    } else {
                      try_it(event); // Eğer template kullanılmamışsa orijinal fonksiyonu çalıştır
                    }
                  };

                  return (
                    <div key={item.template_id} className='customItem-item'>
                      <img src={`/images/bot-icons/${item.image_url}`} alt={`Template ${item.template_name}`}></img>
                      <div className='customItem-texts'>
                        <p className='customItem-title'>{item.template_name}</p>
                        <p className='customItem-text'>{item.description}</p>
                      </div>
                      <button 
                        onClick={handleClick} 
                        id={item.template_id} 
                        value={item.template_url}
                        className='customItem-try-it-btn'
                      >
                        <FormattedMessage id="templates.try.it" />
                      </button>
                    </div>
                  );
                })}
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageGenerator;
