import React, { useContext } from "react";
import Head from "next/head";
import Header from "@/components/Header/Header";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Features from "@/components/Home/Features";
import LlmSlider from "@/components/Home/LlmSlider.js";
import Welcome from "@/components/Home/Welcome.js";
import Context from "@/context/Context";
import Circle from "@/components/Home/Circle";
import Split from "@/components/Split/Split";
import ChatBot from "@/components/ChatBot/ChatBot";
import Design from "@/components/Design/Design";
import Cookie from "@/components/Cookies/Cookies";
import Pricing from "@/components/Pricing/Pricing";
import Accordion from "@/components/Accordion/Accordion";
import Footer from "@/components/Footer/Footer";
import EvolveLink from "@/components/EvolveLink/EvolveLink";
import DesignHomeMessage from "@/components/DesignHomeMessage/DesignHomeMessage";
import DesignHomeHead from "@/components/DesignHomeHead/DesignHomeHead";
import DesignBlogsHome from "@/components/DesignBlogsHome/DesignBlogsHome";

import PageHead from "../Head";
import { useIntl } from 'react-intl';
import { CookieContext } from "@/context/CookieContext"; // CookieContext ekliyoruz

const HomePage = ({ handleLanguageChange }) => {
  const intl = useIntl();
  const { cookiesAccepted, acceptCookies } = useContext(CookieContext); // Context'ten çerez kabul durumunu alıyoruz

  return (
    <>
      <Head>
        {/* Eğer çerezler kabul edilmişse Google Analytics kodunu ekle */}
        {cookiesAccepted && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-4EMBSQJE87"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-4EMBSQJE87');
                `,
              }}
            />
          </>
        )}
      </Head>

      <PageHead title={intl.formatMessage({ id: "home" })} />

      <main className="page-wrapper">
        <Context>
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
            handleLanguageChange={handleLanguageChange}
          />
          <PopupMobileMenu />
          <DesignHomeHead />
          <DesignHomeMessage />
          <DesignBlogsHome />
          <LlmSlider />
          <Design />
          <div className="separator-animated animated-true"></div>
          <Features />
          {/* <ChatBot /> */}
          <div className="separator-animated animated-true"></div>
          <Pricing />
          <Circle />
          <div className="separator-animated animated-true"></div>
          <Accordion />
          <EvolveLink />
          <Footer />
        </Context>
      </main>

      <Cookie />
    </>
  );
};

export default HomePage;
